// For use, select the 'Text_image' element in Strapi
// Will change this in v2 to Two_col

import React from "react";
import { Link } from "gatsby";
import { Container, Grid, Button } from "@material-ui/core";
import ReactMarkdown from "react-markdown";

export default function Twocol(props) {
  const ImgSrc = [`${props.rowimg}`];
  const start_price = props.start_price || "";

  return (
    <div className={props.bgColor}>
      <Container className="Twocol py-24">
        <Grid
          item
          container
          className={`flex ${
            props.order === true ? "flex-row" : "flex-row-reverse"
          }`}
        >
          <Grid
            item
            md={6}
            sm={12}
            className={`flex flex-col justify-center ${
              props.order === true ? "md:pr-24" : "md:pl-24"
            }`}
          >
            <div className="mb-12 md:mb-0">
              {props.title && (
                <h3 className={`${!!start_price ? "mb-3" : "mb-8"}`}>
                  {props.title}
                </h3>
              )}
              {start_price && <h5 className="mb-8">{start_price}</h5>}
              {props.bigsubtitle ? (
                <h4 className="blue">{props.smallsubtitle}</h4>
              ) : null}
              <ReactMarkdown className="descriptionText" source={props.description} />
              {props.smallsubtitle ? (
                <p className="blue">{props.smallsubtitle}</p>
              ) : null}
              {props.children}

              <div className="button-group mt-8">
                {props.blueLink ? (
                  <Link to={props.blueLink}>
                    <Button variant="contained" color="primary">
                      <span className="text-white">{props.blueTitle}</span>
                    </Button>
                  </Link>
                ) : null}
                {props.pdf ? <p>{props.pdf}</p> : null}
                {props.greyLink ? (
                  <Link to={props.greyLink}>
                    <Button variant="contained" color="secondary">
                      <span className="text-white">{props.greyTitle}</span>
                    </Button>
                  </Link>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} className="columnBg flex items-center">
            {props.component ? <>{props.component}</> : <img src={ImgSrc} />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
