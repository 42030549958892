import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"

export default function SvgOne() {
  useEffect(() => {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 2000,
      loop: false,
      direction: "alternate",
      delay: function (el, i) {
        return i * 250
      },
    })
  }, [])

    return (
          <svg 
            version="1.1" 
            id="Layer_1" 
            x="0px" 
            y="0px"
            viewBox="0 0 69 59" 
            className="mx-auto w-20"
          >
        <g>
            <path 
                className="st0" 
                d="M41.7,0c-5.4,0-10,4.5-10,9.9c0,3.3,1.5,6,3.9,7.8c4.2-0.9,8.2-0.9,12.4,0c2.4-1.8,3.6-4.5,3.6-7.8
                    C51.7,4.5,47.1,0,41.7,0z M42,9c0.6,0.3,1.2,0.3,1.8,0.9c0.6,0.3,1.2,1.2,1.2,2.1c0,0.9-0.3,1.8-1.2,2.4c-0.6,0.3-0.9,0.6-1.5,0.6
                    v0.6c0,0.6-0.3,0.9-0.9,0.9s-0.9-0.3-0.9-0.9v-0.6c-1.2-0.3-2.1-0.9-2.4-1.8c-0.3-0.3,0-0.9,0.3-1.2s0.9,0,1.2,0.3
                    c0.3,0.6,0.9,1.2,1.8,1.2c0.6,0,0.9-0.3,1.2-0.3c0.3-0.3,0.6-0.6,0.6-0.9c0-0.9-1.2-1.2-2.1-1.5c-0.6-0.3-1.2-0.3-1.8-0.9
                    c-0.6-0.3-1.2-1.2-1.2-2.1s0.3-1.8,1.2-2.4c0.6-0.3,0.9-0.6,1.5-0.6V4.2c0-0.6,0.3-0.9,0.9-0.9s0.9,0.3,0.9,0.9v0.6
                    c1.2,0.3,2.1,0.9,2.4,1.8c0.3,0.3,0,0.9-0.3,1.2c-0.3,0.3-0.9,0-1.2-0.3c-0.3-0.6-0.9-0.9-1.8-0.9c-0.6,0-0.9,0.3-1.2,0.3
                    c-0.3,0.3-0.6,0.6-0.6,0.9C39.9,8.4,41.1,8.7,42,9z"
                fill="#082844"
            />
            <path 
                className="st0" 
                d="M67.4,27.1c-0.3-0.6-0.6-0.9-0.9-1.5h0.3c1.2-0.3,1.8-1.2,1.8-2.4c0-0.9,0-2.1-0.9-2.7
                c-0.6-0.9-1.5-1.2-2.4-0.9c0,0-0.3,0-0.3,0.3c0,0.3,0,0.3,0.3,0.3c0.9,0.3,1.2,1.8,0.6,3l-0.3,0.3c-0.3,0.3-0.6,0.3-0.9,0
                c-3-3-6.6-4.8-10.6-6.3c0,0,0,0-0.3,0c-0.9,1.2-1.8,2.4-2.7,3.6c-1.2-0.3-2.4-0.6-3.6-0.9c-4.2-0.9-8.2-0.9-12.4,0
                c-0.9,0.3-1.8,0.6-2.7,0.9c-0.6-1.2-1.2-2.4-2.1-3.6c-3,0.9-5.7,2.4-8.2,4.5c0-0.3-0.3-0.3-0.3-0.6c-1.5-1.5-3-2.7-5.1-3.6
                c-0.9-0.3-1.5-0.6-2.4-0.6c-0.3,0-0.6,0-0.6,0.3v0.6c0.6,1.2,0.9,2.1,1.2,3.3c0,0.6,0,1.5,0.3,2.1c0,0-0.3,0-0.3,0.3
                c-3.9,1.8-6.9,4.8-9.1,8.7c-0.3,0.6-0.6,0.6-1.2,0.6c-0.3,0-0.9,0-1.5,0c-1.8,0-3,1.2-3,3c0,1.2,0,2.1,0.3,3.3
                c0.3,0.6,0.6,1.5,1.2,2.1c2.7,2.7,6,4.8,9.4,6.6c3.3,1.8,6.9,3,10.6,3.9c0.3,0,0.6,0.3,0.6,0.6c0.3,1.5,0.3,3,0,4.5v0.6
                c0,0.9,0.6,1.5,1.5,1.5h1.8h4.5c0.6,0,1.2-0.3,1.5-0.9c0.6-1.2,1.5-2.4,2.1-3.6c0-0.3,0.3-0.3,0.3-0.6c6,0.3,12.4,0.3,18.4-1.2v2.4
                v1.8c0,0.9,0.6,1.5,1.5,1.5h6.3c0.6,0,1.2-0.3,1.5-0.9c1.2-1.8,1.8-3.6,2.4-5.4c0.6-2.1,0.9-4.2,1.2-6.6c0,0,0,0,0-0.3
                c0-0.9,0.3-1.5,0.9-2.4c1.2-1.2,1.5-2.4,2.1-3.9C69.5,35.2,69.2,31,67.4,27.1L67.4,27.1z M13.9,33.1c-1.2,0-2.1-0.9-2.1-2.1
                s0.9-2.1,2.1-2.1S16,29.8,16,31C16.3,31.9,15.1,33.1,13.9,33.1z"
                fill="#082844"
            />
        </g>
        </svg>    
    )
}
