// For use, select the 'Masthead' element in Strapi
import React from "react"
import { Container, Grid } from "@material-ui/core"

import BackgroundImage from "gatsby-background-image"

export default function Masthead(props) {
  const SlideBg = [`url('${props.mhbackground}')`]

  return (
    <>
      {props.model ? (
        <div className="modelhead py-12 bg-green">
          <Container>
            <Grid item md={6}>
              <div className="white-text">
                <h1>{props.mhtitle}</h1>
                <p>{props.mhsubtitle}</p>
              </div>
            </Grid>
          </Container>
        </div>
      ) : (
        <BackgroundImage Tag={`div`} fluid={SlideBg} className={`masthead`}>
          <div className="masthead-bg"></div>
          <Container>
            <Grid item md={6} className="white-text">
              <h1>{props.mhtitle}</h1>
              <p>{props.mhsubtitle}</p>
            </Grid>
          </Container>
        </BackgroundImage>
      )}
    </>
  )
}
