import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"

export default function SvgOne() {
  useEffect(() => {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 2000,
      loop: false,
      direction: "alternate",
      delay: function (el, i) {
        return i * 250
      },
    })
  }, [])

  return (
    <svg 
        version="1.1" 
        id="Layer_1" 
        x="0px" 
        y="0px"
        viewBox="0 0 17.7 25.3"
        className="mx-auto w-12"
    >
    <path 
        className="st0" 
        d="M8.8,0C4,0,0,4,0,8.8c0,1.9,0.6,4.1,1.7,5.6l7.2,10.9L16,14.4c1-1.5,1.7-3.6,1.7-5.6C17.7,4,13.7,0,8.8,0z
            M8.8,12.2c-1.9,0-3.4-1.5-3.4-3.4S7,5.5,8.8,5.5s3.4,1.5,3.4,3.4S10.7,12.2,8.8,12.2z"
        fill="#2F80ED"
    />
    </svg>
  )
}